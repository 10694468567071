module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-57423728-1","head":false,"anonymize":false,"respectDNT":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/subir/workspace/webdev/desdevpro.com/plugins/gatsby-remark-copy-svg/index.js"},{"resolve":"/home/subir/workspace/webdev/desdevpro.com/plugins/gatsby-remark-copy-image/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":600,"disableBgImage":true,"showCaptions":false}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Monokai"}},{"resolve":"@weknow/gatsby-remark-codepen","options":{"theme":"dark","height":500}},"gatsby-remark-lazy-load"],"rehypePlugins":[null]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
